import request from '@/utils/requestmobile';

// import request from '@/utils/request'
// const baseUrl = "";
const baseUrl = "/api"; // 发布的时候需要用
const mapData = 'https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json';

// 返回导入地址
export function getimportClanUser() {
    return baseUrl + '/clanUser/importClanUser'
}
// 返回导入地址
export function getuploadurl() {
    return baseUrl + '/common/upload'
}

// 上传单个文件
export function uploadcommonOne(params) {
    return request({
        url: baseUrl + '/common/upload',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: -1, 
        transformRequest(data) {
            return data;
        }
    });
}


// 获取课题申报-信息数据（1-4步详情数据）
export function login(params) {
    return request({
        url: baseUrl + '/login',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 获取商品类别列表
export function selectGoodsCategories(params) {
    return request({
        url: baseUrl + '/goodsCategory/selectGoodsCategories',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return data;
        }
    });
}

// 添加商品类别
export function addCategory(params) {
    return request({
        url: baseUrl + '/goodsCategory/addCategory',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 编辑商品类别
export function updateCategory(params) {
    return request({
        url: baseUrl + 'goodsCategory/updateCategory',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 删除商品类别
export function removeCageGory(params) {
    return request({
        url: baseUrl + '/goodsCategory/removeCageGory',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 获取商品列表 
export function selectGoods(params) {
    return request({
        url: baseUrl + '/goods/selectGoods',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return data;
        }
    });
}

// 新增商品 
export function addGoods(params) {
    return request({
        url: baseUrl + '/goods/addGoods',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 编辑商品 
export function updateGoods(params) {
    return request({
        url: baseUrl + '/goods/updateGoods',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 删除商品 
export function removeGoods(params) {
    return request({
        url: baseUrl + '/goods/removeGoods?goodsId=' + params.goodsId,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}



// 用户列表 
export function userList(params) {
    return request({
        url: baseUrl + '/user/list',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return data;
        }
    });
}

// 获取新闻列表 
export function selectClanNewsList(params) {
    return request({
        url: baseUrl + '/clanNews/selectClanNewsList',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return data;
        }
    });
}

// 新增新闻动态 
export function addClanNews(params) {
    return request({
        url: baseUrl + '/clanNews/addClanNews',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 新增新闻动态 
export function updateClanNews(params) {
    return request({
        url: baseUrl + '/clanNews/updateClanNews',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}


// 删除新闻动态 
export function newremoveClanNews(params) {
    return request({
        url: baseUrl + '/clanNews/removeClanNews',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}



// 获取相册列表
export function selectClanAlbum(params) {
    return request({
        url: baseUrl + '/clanAlbum/selectClanAlbum',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 用户相册 - 新增
export function addClanAlbum(params) {
    return request({
        url: baseUrl + '/clanAlbum/addClanAlbum',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}
// 用户相册 - 编辑
export function updateClanAlbum(params) {
    return request({
        url: baseUrl + '/clanAlbum/updateClanAlbum',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}
// 用户相册 - 删除
export function removeClanAlbum(params) {
    return request({
        url: baseUrl + `/clanAlbum/removeClanAlbum?albumId=${params.albumId}`,
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}
// 用户相册 - 编辑
export function uploadPics(params) {
    return request({
        url: baseUrl + '/clanAlbum/uploadPics',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}
// 获取家族文化列表
export function selectClanCultureList(params) {
    return request({
        url: baseUrl + '/clanCulture/selectClanCultureList',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 获取家族文化 - 新增
export function addClanCulture(params) {
    return request({
        url: baseUrl + '/clanCulture/addClanCulture',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 获取家族文化 - 编辑
export function updateClanCulture(params) {
    return request({
        url: baseUrl + '/clanCulture/updateClanCulture',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 获取家族文化 - 删除
export function removeClanNews(params) {
    return request({
        url: baseUrl + '/clanCulture/removeClanNews',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}

// 获取个人事迹列表
export function deedsList(data) {
    return request({
        url: baseUrl + `/deeds/list`,
        data: data,
        method: 'POST',
        header: {
            "content-type": "application/x-www-form-urlencoded"
        },
        transformRequest(data) {
            return data;
        }
    })
}

// 添加个人事迹列表
export function deedsAdd(data) {
    // console.log(data)
    return request({
        url: baseUrl + `/deeds/add`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 编辑个人事迹列表
export function deedsEditorList(data) {
    return request({
        url: baseUrl + `/deeds/editor`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 删除个人事迹列表
export function deedsRemoveList(data) {
    return request({
        url: baseUrl + `/deeds/remove`,
        params: data,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 家谱列表
export function selectClanInfos(data) {
    return request({
        url: baseUrl + `/clan/selectClanInfos`,
        params: data,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return data;
        }
    })
}

// 家谱-编辑
export function editorClan(data) {
    return request({
        url: baseUrl + `/clan/editor`,
        data: data,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 导出家谱
export function exportClanUser(data) {
    return request({
        url: baseUrl + `/clanUser/exportClanUser`,
        params: data,
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 系统日志列表
export function selectOperLogs(data) {
    return request({
        url: baseUrl + `/operLog/selectOperLogs`,
        params: data,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 系统日志列表 - 详情
export function selectLogById(data) {
    return request({
        url: baseUrl + `/operLog/selectLogById?operLogId=${data}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

// 权限升级审核列表
export function selectApplyClanUsers(data) {
    return request({
        url: baseUrl + `/clanUser/selectApplyClanUsers`,
        params: data,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 删除家谱
export function remove(data) {
    return request({
        url: baseUrl + `/clan/remove?clanId=${data}`,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 获取栏目
export function selectColumn(data) {
    return request({
        url: baseUrl + `/clan/selectColumn`,
        data: data,
        method: 'POST',
        header: {
            "content-type": "application/x-www-form-urlencoded"
        },
        timeout: -1, 
        transformRequest(data) {
            return data;
        }
    })
}

// 获取栏目
export function selectTreeUserByClanId(data) {
    return request({
        url: baseUrl + `/clanUser/selectTreeUserByClanId`,
        params: data,
        method: 'GET',
        header: {
            "content-type": "application/json"
        },
        timeout: -1, 
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 微信小程序 - 获取苏式吊线图人员结构
export function getSovietStyle(data) {
    return request({
        url: baseUrl + `/clanUser/getSovietStyle`,
        params: data,
        method: 'GET',
        header: {
            "content-type": "application/json"
        },
        timeout: -1, 
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 微信小程序 - 获取家谱的详情
export function getClanInfoById(data) {
    return request({
        url: baseUrl + `/clan/getClanInfoById`,
        params: data,
        method: 'GET',
        header: {
            "content-type": "application/json"
        },
        timeout: -1, 
    })
}

// 导入家谱人员 // 上传
export function importClanUser(params,clanId) {
    return request({
        url: baseUrl + `/clanUser/importClanUser?clanId=${clanId}`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: -1, 
        transformRequest(data) {
            return data;
        }
    });
}


// 后台创建家谱
export function addClanJiaPu(data) {
    return request({
        url: baseUrl + `/clan/addClan`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 后台创建家谱 -- 获取管理员账号
export function chanClanManager(data) {
    return request({
        url: baseUrl + `/clan/chanClanManager`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest(data) {
            return data;
        }
    })
}

// 获取百家姓
export function selectSurnamesList(data) {
    return request({
        url: baseUrl + `/genealogy/selectSurnamesList`,
        params: data,
        method: 'get',
        headers: {
            "Content-type": "application/json"
        },
    })
}
// 获取家谱管理
export function getgenealogyList(data) {
    return request({
        url: baseUrl + `/genealogy/list2Admin`,
        data: data,
        method: 'POST',
        header: {
            "content-type": "application/form-data"
        },
        transformRequest(data) {
            return data;
        }
    })
}

// // 获取家谱管理 - 新增
export function getgenealogyAdd(data) {
    return request({
        url: baseUrl + `/genealogy/add`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 获取分卷列表
export function selectByGenealogyId(data) {
    return request({
        url: baseUrl + `/volume/selectByGenealogyId`,
        params: data,
        method: 'get',
        headers: {
            "Content-type": "application/json"
        },
    })
}


// 后台创建家谱 - 支系加剖
export function addClanZhiXiJiaPu(data) {
    return request({
        url: baseUrl + `/clan/add`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 微信小程序 - 获取苏式吊线图人员结构 - 横板
export function getModernStyle(data) {
    return request({
        url: baseUrl + `/clanUser/getModernStyle`,
        params: data,
        method: 'GET',
        header: {
            "content-type": "application/json"
        },
    })
}

// 获取家谱管理 - 编辑
export function getgenealogyUpdate(data) {
    return request({
        url: baseUrl + `/genealogy/update`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 获取家谱管理 - 删除
export function getgenealogyRemove(data) {
    return request({
        url: baseUrl + `/genealogy/remove?id=${data.id}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

// 获取分卷列表 -  新增
export function volumeAddVolume(data) {
    return request({
        url: baseUrl + `/volume/addVolume`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 获取分卷列表 -  删除
export function volumeRemove(data) {
    return request({
        url: baseUrl + `/volume/remove?id=${data.id}`,
        data: data,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}


// 获取分卷列表 -  编辑
export function volumeUpdate(data) {
    return request({
        url: baseUrl + `/volume/update`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 微信小程序 - 获取欧式吊线图人员结构
export function getEuropeanStyle(data) {
    return request({
        url: baseUrl + `/volume/getEuropeanStyle`,
        params: data,
        method: 'GET',
        header: {
            "content-type": "application/json"
        },
    })
}

// 设置家谱管理员 - 总家谱
export function genealogyAddGenealogyManager(data) {
    return request({
        url: baseUrl + `/genealogy/addGenealogyManager`,
        params: data,
        method: 'post',
        header: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 设置家谱管理员 - 分卷
export function volumeAddGenealogyManager(data) {
    return request({
        url: baseUrl + `/volume/addVolumeManager`,
        params: data,
        method: 'post',
        header: {
            "content-type": "application/x-www-form-urlencoded"
        },
        transformRequest(data) {
            return data
        }
    })
}

// 设置家谱管理员 - 分卷 - 取消水印
export function volumeUpdateWater(data) {
    return request({
        url: baseUrl + `/volume/updateWater`,
        params: data,
        method: 'post',
        header: {
            "content-type": "application/x-www-form-urlencoded"
        },
        transformRequest(data) {
            return data
        }
    })
}

// 设置家谱管理员 - 支系家谱 - 取消水印
export function clanUpdateWater(data) {
    return request({
        url: baseUrl + `/clan/updateWater`,
        params: data,
        method: 'post',
        header: {
            "content-type": "application/x-www-form-urlencoded"
        },
        transformRequest(data) {
            return data
        }
    })
}

// 百家姓 - 获取下面的栏目
export function lanmuList(data) {
    return request({
        url: baseUrl + `/column/list`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 百家姓 - 获取下面的栏目
export function contentList(data) {
    return request({
        url: baseUrl + `/column/contentList`,
        params: data,
        method: 'get',
        header: {
            "content-type": "application/x-www-form-urlencoded"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 百家姓 - 获取自定义栏目信息
export function systemColumn(data) {
    return request({
        url: baseUrl + `/column/systemColumn`,
        params: data,
        method: 'get',
        header: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 百家姓 - 新增百家姓下栏目内容
export function addSelfColumn(data) {
    return request({
        url: baseUrl + `/column/addSelfColumn`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}


// 新增内容
export function updateSelfColumn(data) {
    return request({
        url: baseUrl + `/column/updateSelfColumn`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 二次导入
export function importFile2Result(data) {
    return request({
        url: baseUrl + `/clanUser/importFile2Result`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        timeout: -1, 
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}







// 现代氏获取总页码数
export function getModernStylePage(data) {
    return request({
        url: baseUrl + `/clanUser/getModernStylePage`,
        params: data,
        method: 'get',
        timeout: -1, 
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 古代获取总页码数
export function getSovietStylePage(data) {
    return request({
        url: baseUrl + `/clanUser/getSovietStylePage`,
        params: data,
        method: 'get',
        timeout: -1, 
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}


// 获取支系下世数列表
export function selectUserIndexs(data) {
    return request({
        url: baseUrl + `/clan/selectUserIndexs`,
        params: data,
        method: 'get',
        timeout: -1, 
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 家谱用户权限审核
export function clanUserAudit(data) {
    return request({
        url: baseUrl + `/clanUser/audit`,
        data: data,
        method: 'POST',
        timeout: -1, 
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 家谱用户权限审核
export function userAudit(data) {
    return request({
        url: baseUrl + `/user/audit`,
        params: data,
        method: 'get',
        timeout: -1, 
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 获取用户家谱权限申请列表
export function selectApplyUsers(data) {
    return request({
        url: baseUrl + `/user/selectApplyUsers`,
        params: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 调整用户创建家谱次数
export function updateUserMarkGenealogyNum(data) {
    return request({
        url: baseUrl + `/user/updateUserMarkGenealogyNum`,
        params: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 获取待审核人员列表
export function selectAuditClanUserInfos(data) {
    return request({
        url: baseUrl + `/clanUser/selectAuditClanUserInfos`,
        params: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 人员审核
export function userAuditClanUser(data) {
    return request({
        url: baseUrl + `/clanUser/auditClanUser`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 新增人员
export function addClanUser(data) {
	return request({
		url: baseUrl + `/clanUser/addClanUser`,
		data: data,
		method: 'post',
		headers: {
			"Content-Type": "application/json"
		},
        transformRequest(data) {
            return JSON.stringify(data);
        }
	})
}

// 编辑家谱人员
export function updateClanUser(data) {
	return request({
		url: baseUrl + `/clanUser/updateClanUser`,
		data: data,
		method: 'post',
		headers: {
			"Content-Type": "application/json"
		},
        transformRequest(data) {
            return JSON.stringify(data);
        }
	})
}

// 删除人员
export function removeClanUser(data) {
	return request({
		url: baseUrl + `/clanUser/removeClanUser`,
		params: data,
		method: 'GET',
		header: {
			"content-type": "application/json"
		},
	})
}

// 通过id获取人员性情
export function getClanUserInfoByClanUserId(data) {
	return request({
		url: baseUrl + `/clanUser/getClanUserInfoByClanUserId`,
		params: data,
		method: 'get',
		header: {
			"content-type": "application/x-www-form-urlencoded"
		},
	})
}


// 添加收费信息 -  新增
export function chargeAdd(data) {
    return request({
        url: baseUrl + `/charge/add`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 获取收费信息
export function getChargeInfo(data) {
    return request({
        url: baseUrl + `/charge/getChargeInfo`,
        data: data,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}


// 编辑收费信息 -  编辑
export function chargeUpdate(data) {
    return request({
        url: baseUrl + `/charge/update`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}


// 现代氏世系图人员总数（包含世数节点）
export function selectModernStylePage(data) {
    return request({
        url: baseUrl + `/clanUser/selectModernStylePage`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 现代氏世系图人员总数（包含世数节点）
export function selectModernStyle(data) {
    return request({
        url: baseUrl + `/clanUser/selectModernStyle`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 欧式数据
export function getEuropeanBookSpectrum(data) {
    return request({
        url: baseUrl + `/clanUser/getEuropeanBookSpectrum`,
        params: data,
        method: 'get',
        timeout: -1, 
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}

// 苏式数据
export function getSovietBookSpectrum(data) {
    return request({
        url: baseUrl + `/clanUser/getSovietBookSpectrum`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 一家清数据
export function getFamilySpectrum(data) {
    return request({
        url: baseUrl + `/clanUser/getFamilySpectrum`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 一派清数据
export function getFactionSpectrum(data) {
    return request({
        url: baseUrl + `/clanUser/getFactionSpectrum`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 是否调用过pdf
export function checkPdfDown(data) {
    return request({
        url: baseUrl + `/clan/checkPdf`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 是否调用过pdf
export function markPdf(data) {
    return request({
        url: baseUrl + `/clan/markPdf`,
        params: data,
        method: 'get',
        headers: {
            "content-type": "application/json"
        },
        timeout: -1, 
        transformRequest(data) {
            return JSON.stringify(data);
        }
    })
}
// 上传单个文件
export function uploadClanImg(params) {
    return request({
        url: baseUrl + '/common/upload/uploadClanImg',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: -1, 
        transformRequest(data) {
            return data;
        }
    });
}
// 获取栏目数据
export function getColumn(params) {
    return request({
        url: baseUrl + `/clan/getColumn/${params.clanId}/${params.columnType}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
        timeout: -1, 
        transformRequest(data) {
            return data;
        }
    });
}
// 编辑栏目
export function updateClanColumn(data) {
    return request({
        url: baseUrl + `/clan/updateClanColumn`,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        timeout: -1, 
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}
// 搜索人员
export function searchClanUser2Tree(data) {
    return request({
        url: baseUrl + `/clanUser/searchClanUser2Tree`,
        params: data,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}
// 册谱中的搜索 - 展示的页面
export function selectChildByParentId(data) {
    return request({
        url: baseUrl + `/clanUser/selectChildByParentId/${data.parentClanUserId}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest(data) {
            return JSON.stringify(data);
        }
    });
}



